import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Observable } from 'rxjs';

import { DenialReason } from '@app/features/renewals/shared/renewals.type';
import { FormModel } from '@app/shared';
import { DynamicFormGroup } from '@app/utils/forms/base';

import { ChangeRxActions } from '../store/change-rx.actions';
import { ChangeRxSelectors } from '../store/change-rx.selectors';
import {
  ChangeRx,
  ChangeRxCartState,
  PrescriberAuthorizationSubcodes,
} from './change-rx.type';

const priorAuthRequiredFieldValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  const authNumber = control.get('authNumber');
  const submitWithoutAuthNumber = control.get('submitWithoutAuthNumber');

  return authNumber.value || submitWithoutAuthNumber.value
    ? null
    : { required: true };
};

type controlsByPrescriberAuthorizationSubcode = {
  [key in keyof typeof PrescriberAuthorizationSubcodes]: { name: string };
};

export const priorAuthNumberMaxLength = 35;

export const customRxChangeRequestOptionId = -1;

export const controlsByPrescriberAuthorizationSubcode: controlsByPrescriberAuthorizationSubcode = {
  A: { name: 'stateLicenseNumber' },
  B: { name: 'DEALicenseNumber' },
  C: { name: 'DEALicenseNumber' },
  D: { name: 'stateControlledSubstanceNumber' },
  E: { name: 'stateControlledSubstanceNumber' },
  F: { name: 'NADEALicenseNumber' },
  G: { name: 'NPILicenseNumber' },
  H: { name: 'prescriberEnrollmentDate' },
  I: { name: 'specialty' },
  J: { name: 'REMSEnrollmentDate' },
  L: { name: 'supervisingProviderInformation' },
  M: { name: 'certificateToPrescribeNumber' },
};

export class ChangeRxForm extends DynamicFormGroup {
  model: FormModel;

  constructor(
    private actions: ChangeRxActions,
    private selectors: ChangeRxSelectors,
    public changeRx: ChangeRx,
  ) {
    super();
    this.addControls();
    this.buildFormModel();
  }

  update(changes: Partial<ChangeRx>): Observable<ChangeRx> {
    this.actions.update({ id: changes.id, changes });
    return this.selectors.getById(changes.id);
  }

  approveChanges(password, cartPushToken) {
    if (this.changeRx.rxChangeRequest) {
      this.changeRx.rxChangeRequest.cartState = ChangeRxCartState.approved;
    }
    this.actions.submit({
      submitAction: 'Approved',
      changeRx: {
        ...this.changeRx,
        ...this.serializedValue,
        password,
        cartPushToken,
      },
    });
  }

  denyChanges(password, denialReason: DenialReason) {
    if (this.changeRx.rxChangeRequest) {
      this.changeRx.rxChangeRequest.cartState = ChangeRxCartState.denied;
    }
    this.actions.submit({
      submitAction: 'Denied',
      changeRx: {
        ...this.changeRx,
        ...this.serializedValue,
        denialReason,
        password,
      },
    });
  }

  private buildFormModel() {
    this.model = new FormModel(this.controls, {
      saveFunction: () => this.update(this.serializedValue),
      saveInvalid: true,
      autosaveDelay: 200,
    });
  }

  private get serializedValue(): Partial<ChangeRx> {
    const changes: Partial<ChangeRx> = {
      id: this.changeRx.id,
    };

    const formValue = this.controls.value;
    switch (this.changeRx.changeRequestType) {
      case 'G':
      case 'T':
      case 'D':
      case 'S':
      case 'OS':
        changes.rxChangeRequestAttributes = {
          id: this.changeRx.rxChangeRequest.id,
          approvedQuantity: formValue['quantity'],
          approvedFills: formValue['refills'],
          medicationRegimenId: formValue['medicationRegimen'].id,
          dispenseAsWritten: formValue['dispenseAsWritten'],
          earliestFillDate: formValue['earliestFillDate'],
          selectedRxChangeRequestOptionId:
            formValue['selectedRxChangeRequestOptionId'] ===
            customRxChangeRequestOptionId
              ? null
              : formValue['selectedRxChangeRequestOptionId'],
          cartState: this.changeRx.rxChangeRequest.cartState,
        };

        if (formValue['medicationRegimen'].isCustomRegimen) {
          changes.customMedicationRegimen = formValue['medicationRegimen'];
        }

        break;
      case 'P':
        changes.rxChangeRequestPriorAuthorizationAttributes = {
          id: this.changeRx.rxChangeRequestPriorAuthorization.id,
          approvedValue: formValue['authNumber'],
          submitWithoutApproval: formValue['submitWithoutAuthNumber'],
        };
        break;
      case 'U':
        changes.rxChangeRequestPrescriberAuthorizationsAttributes = [];
        this.changeRx.rxChangeRequestPrescriberAuthorizations.forEach(
          rxChangeRequestPrescriberAuthorization => {
            const mappedControl =
              controlsByPrescriberAuthorizationSubcode[
                rxChangeRequestPrescriberAuthorization.subcode
              ];

            changes.rxChangeRequestPrescriberAuthorizationsAttributes.push({
              id: rxChangeRequestPrescriberAuthorization.id,
              value: formValue[mappedControl.name],
            });
          },
        );
        break;
      default:
        break;
    }

    return changes;
  }

  private addControls() {
    let controls: {
      name: string;
      validators?: ValidatorFn[];
      value?: any;
    }[] = [];

    const formGroupValidators = [];

    switch (this.changeRx.changeRequestType) {
      case 'G':
      case 'T':
      case 'D':
      case 'S':
      case 'OS':
        controls = [
          {
            name: 'selectedRxChangeRequestOptionId',
            value:
              this.changeRx.rxChangeRequest.selectedRxChangeRequestOptionId ||
              customRxChangeRequestOptionId,
          },
          {
            name: 'medicationRegimen',
            value: this.changeRx.rxChangeRequest.medicationRegimen,
          },
          {
            name: 'medicationPackageSizeId',
            value: this.changeRx.rxChangeRequest
              .approvedMedicationPackageSizeId,
          },
          {
            name: 'quantity',
            value: this.changeRx.rxChangeRequest.approvedQuantity,
          },
          {
            name: 'refills',
            value: this.changeRx.rxChangeRequest.approvedFills,
          },
          {
            name: 'dispenseAsWritten',
            value: this.changeRx.rxChangeRequest.dispenseAsWritten,
          },
          {
            name: 'earliestFillDate',
            value: this.changeRx.rxChangeRequest.earliestFillDate,
          },
        ];
        break;
      case 'P':
        controls = [
          {
            name: 'submitWithoutAuthNumber',
            value: this.changeRx.rxChangeRequestPriorAuthorization
              .submitWithoutApproval,
          },
          {
            name: 'authNumber',
            value: this.changeRx.rxChangeRequestPriorAuthorization
              .approvedValue,
            validators: [Validators.maxLength(priorAuthNumberMaxLength)],
          },
        ];
        formGroupValidators.push(priorAuthRequiredFieldValidator);

        break;
      case 'U':
        controls = this.changeRx.rxChangeRequestPrescriberAuthorizations.map(
          rxChangeRequestPrescriberAuthorization => ({
            ...controlsByPrescriberAuthorizationSubcode[
              rxChangeRequestPrescriberAuthorization.subcode
            ],
            value: rxChangeRequestPrescriberAuthorization.value,
          }),
        );
        break;
      default:
        break;
    }

    controls.forEach(control =>
      this.addControl({
        name: control.name,
        defaultValue: control.value,
        validators: control.validators,
      }),
    );

    if (formGroupValidators.length) {
      this.controls.setValidators(formGroupValidators);
    }
  }
}
