<div class="padding-normal"
     om-layout-wrap
     om-layout>
  <omg-change-rx-status-approval></omg-change-rx-status-approval>
  <div om-flex="100"
       class="padding-normal-vertical">
    <div><strong>Medication Change</strong></div>
    <omg-checkout-list-item [medication]="changeRx.rxChangeRequest"
                            [readyToSign]="readyToSignControl"
                            (checked)="readyToSign.emit($event.readyToSign)"
                            [isRenewal]="true">
    </omg-checkout-list-item>
  </div>
</div>
