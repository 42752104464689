import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';

import { MedicationRegimen } from '@app/modules/medications/shared';

import {
  ChangeRxForm,
  customRxChangeRequestOptionId,
} from '../../shared/change-rx-form';
import {
  ChangeRx,
  ChangeRxTypes,
  RxChangeRequestOption,
} from '../../shared/change-rx.type';

@Component({
  selector: 'omg-change-rx-medication-change',
  templateUrl: './change-rx-medication-change.component.html',
  styleUrls: ['./change-rx-medication-change.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeRxMedicationChangeComponent implements OnChanges {
  @Input() form: ChangeRxForm;
  @Input() changeRx: ChangeRx;

  regimenEditable = true;
  changeRxTypes = ChangeRxTypes;
  rxChangeRequestOptions: RxChangeRequestOption[];

  ngOnChanges(): void {
    this.setRxChangeRequestOptions();
    this.checkSelectedRxChangeRequestOptionId();
  }

  regimenUpdate(medicationRegimen: MedicationRegimen) {
    this.form.controls.patchValue({ medicationRegimen });
  }

  updatedSelectedRxChangeRequestOption(option: RxChangeRequestOption) {
    this.form.controls.patchValue({
      quantity: option.quantity,
      refills: option.fills,
      dispenseAsWritten: option.dispenseAsWritten,
      earliestFillDate: option.earliestFillDate,
      medicationRegimen: option.medicationRegimen,
      medicationPackageSizeId:
        option.packageOptions.defaultOption?.matchedOption?.id,
    });
  }

  private matchedRxChangeRequestOption(): RxChangeRequestOption {
    const {
      quantity,
      refills,
      dispenseAsWritten,
      earliestFillDate,
      medicationRegimen,
    } = this.form.controls.value;

    return this.changeRx.rxChangeRequest.rxChangeRequestOptions.find(
      option =>
        option.quantity === quantity &&
        option.fills === refills &&
        option.dispenseAsWritten === dispenseAsWritten &&
        +option.earliestFillDate === +earliestFillDate && // +'s needed for date compare
        option.medicationRegimen.id === medicationRegimen.id,
    );
  }

  private setRxChangeRequestOptions() {
    this.rxChangeRequestOptions = [].concat(
      this.changeRx.rxChangeRequest.rxChangeRequestOptions,
    );

    if (!this.matchedRxChangeRequestOption()) {
      this.rxChangeRequestOptions.push(this.buildCustomRxChangeRequestOption());
    }
  }

  private checkSelectedRxChangeRequestOptionId() {
    const matchedOptionId =
      this.matchedRxChangeRequestOption()?.id || customRxChangeRequestOptionId;
    if (matchedOptionId !== this.form.value.selectedRxChangeRequestOptionId) {
      this.form.controls.patchValue({
        selectedRxChangeRequestOptionId: matchedOptionId,
      });
    }
  }

  private buildCustomRxChangeRequestOption(): RxChangeRequestOption {
    return {
      ...this.changeRx.rxChangeRequest,
      id: customRxChangeRequestOptionId,
      custom: true,
      fills: this.changeRx.rxChangeRequest.approvedFills,
      quantity: this.changeRx.rxChangeRequest.approvedQuantity,
    };
  }
}
