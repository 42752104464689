import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import { ChangeRx } from '../../shared/change-rx.type';

@Component({
  selector: 'omg-change-rx-medication-change-status',
  templateUrl: './change-rx-medication-change-status.component.html',
  styleUrls: ['./change-rx-medication-change-status.component.scss'],
})
export class ChangeRxMedicationChangeStatusComponent {
  @Input() changeRx: ChangeRx;
  @Output() readyToSign = new EventEmitter<boolean>();

  readyToSignControl = new FormControl();
}
